<div *ngIf="isVisible" [@slideInOut] class="mn accordion accordion--list h-full bg-background-main px-16">
  <div class="flex h-62 justify-between w-full items-center">
    <div class="mn__icons flex items-center gap-8">
      <mat-icon [svgIcon]="svgIcons.HERALD" />
      <mat-icon [svgIcon]="svgIcons.PEN_LOGO" />
    </div>
    <mat-icon [svgIcon]="svgIcons.CLOSE" (click)="close()" />
  </div>
  <mat-expansion-panel
    *ngFor="let item of items; let i = index"
    [class.last]="i === items.length - 1"
    class="mn__item"
    [class.mn__item--empty]="!item.items?.length"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-container *ngIf="item.items?.length; else link">
          <span>{{ item.label }}</span>
        </ng-container>
        <ng-template #link>
          <a [routerLink]="item.url">{{ item.label }}</a>
        </ng-template>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="item.items?.length">
      <div class="flex flex-col pl-8 gap-12">
        <a *ngFor="let link of item.items" class="mn__sub-item" [routerLink]="link.url">
          {{ link.label }}
        </a>
      </div>
    </div>
  </mat-expansion-panel>
</div>
