import { BehaviorSubject } from 'rxjs'
import { BtnComponent } from 'src/app/ui-kit/component/btn/btn.component'

import { NavigationComponent } from './components/navigation/navigation.component'
import { NavigationItem } from './components/navigation/shared/types'

import { AsyncPipe, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { Router, RouterLink } from '@angular/router'

import { navItems } from 'src/app/app.navigation'

import { AuthService } from 'src/app/core/services/auth.service'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NavigationComponent,
    MatIconModule,
    SvgIconsModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    RouterLink,
    NgIf,
    BtnComponent,
    AsyncPipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  svgIcons = SvgIcon

  mockNavigationItems: NavigationItem[] = navItems

  name = 'Іван К.'

  @Input() isHero!: boolean

  @Input() isPortal!: boolean

  @Input() isBackHeader!: boolean

  isSubNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  menuEvent(key: string): void {
    const currentRole = this.authService.getCurrentMockRole()

    const isAdmin = currentRole === 'admin'
    switch (key) {
      case 'profile':
        this.router.navigate([isAdmin ? '/admin/profile' : '/profile'])
        break
      case 'logout':
        this.authService.setMockRole(null)
        break
    }
  }

  goToLogin(): void {
    this.router.navigate(['/login'])
  }

  subNavigationEvent(event: boolean): void {
    this.isSubNavigation$.next(event)
  }

  goBack(): void {
    window.history.back()
  }
}
