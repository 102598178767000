<ng-container *ngIf="subNavigationItems$ | async as subNavigationItems">
  <app-sub-navigation *ngIf="subNavigationItems?.length" [items]="subNavigationItems" (closeSubNavigationEvent)="closeSubNavigation()" />
</ng-container>
<div class="navigation flex items-center gap-24">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item?.items?.length; else link">
      <div class="navigation__item text-14 cursor-pointer" (click)="openItems(item)">
        <span>
          {{ item.label }}
        </span>
        <mat-icon [svgIcon]="svgIcons.NAV_ARROW" />
      </div>
    </ng-container>
    <ng-template #link>
      <a class="navigation__item text-14" [routerLink]="item.url">
        <span>
          {{ item.label }}
        </span>
      </a>
    </ng-template>
  </ng-container>
  <!-- [matMenuTriggerFor]="menu" -->
  <div class="navigation__burger flex items-center gap-4 ml-auto">
    <mat-icon [svgIcon]="svgIcons.BURGER" (click)="openMobile()" />
  </div>

  <div *ngIf="isShowMobileNavigation$ | async" class="navigation__mobile fixed w-full top-0 left-0">
    <app-mobile-navigation [items]="items" [isPortal]="isPortal" (closeEvent)="closeMobile()" />
  </div>
</div>

<mat-menu #menu="matMenu">
  <a *ngIf="!isPortal" mat-menu-item (click)="menuEvent('profile')" class="menu__item">Mій профіль</a>
  <a *ngIf="isPortal" mat-menu-item [routerLink]="'/login'" class="menu__item">Увійти до кабінету</a>
  <div *ngFor="let item of items" [routerLink]="item.url" class="item">
    <a mat-menu-item [routerLink]="item.url" class="menu__item">{{ item.label }}</a>
  </div>
  <button *ngIf="!isPortal" mat-menu-item (click)="menuEvent('logout')" class="menu__item">Вийти</button>
</mat-menu>
