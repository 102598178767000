<div
  [class.header--portal]="isHero"
  [class.header--sub]="isSubNavigation$ | async"
  class="header flex items-center gap-20 w-full h-48 md:h-auto md:py-16 md:pl-8 md:pr-16"
>
  <div *ngIf="!isBackHeader; else back" class="wrapper flex items-center gap-20 w-full h-full">
    <a class="header__support rounded-10 w-32 h-32 md:w-48 md:h-48 flex items-center justify-center bg-background-black" [routerLink]="'/'">
      <mat-icon [svgIcon]="svgIcons.SUPPORT" />
    </a>
    <div class="header__navigations">
      <app-navigation [items]="mockNavigationItems" [isPortal]="isPortal" (isSubNavigationEvent)="subNavigationEvent($event)" />
    </div>

    <ng-container *ngIf="isPortal; else profile">
      <div class="header__menu header__menu--login">
        <app-btn [title]="'Увійти'" [hasGradient]="false" (clickEvent)="goToLogin()" class="header__login" />
      </div>
    </ng-container>

    <ng-template #profile>
      <div [matMenuTriggerFor]="menu" class="header__menu header__menu flex items-center gap-4 ml-auto">
        <span class="text-13">{{ name }}</span>
        <mat-icon [svgIcon]="svgIcons.POLYGON" />
      </div>

      <mat-menu #menu="matMenu" class="profile-menu" xPosition="after">
        <a mat-menu-item (click)="menuEvent('profile')" class="menu__item">Mій профіль</a>
        <button mat-menu-item (click)="menuEvent('logout')" class="menu__item">Вийти</button>
      </mat-menu>
    </ng-template>
  </div>

  <ng-template #back>
    <div class="wrapper flex items-center gap-20 w-full h-full justify-between">
      <div class="flex gap-4 items-center cursor-pointer" (click)="goBack()">
        <mat-icon [svgIcon]="svgIcons.ARROW_BACK" />
        <span class="text-13 hidden md:block">Повернутись на сайт</span>
      </div>
      <div class="header__support">
        <mat-icon [svgIcon]="svgIcons.SUPPORT" />
      </div>
    </div>
  </ng-template>
</div>
