import { timer } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { RouterLink } from '@angular/router'

import { slideInOutAnimation } from 'src/app/core/animations/slide-in-out.snimation'
import { ClickOutsideDirective } from 'src/app/core/directives/click-outside.directive'

@Component({
  selector: 'app-sub-navigation',
  standalone: true,
  imports: [RouterLink, NgFor, ClickOutsideDirective, NgIf],
  templateUrl: './sub-navigation.component.html',
  styleUrl: './sub-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation],
})
export class SubNavigationComponent {
  @Input() items: NavigationItem[] = []

  @Output() closeSubNavigationEvent: EventEmitter<void> = new EventEmitter<void>()

  isVisible = true

  hide(): void {
    this.isVisible = !this.isVisible
    timer(150).subscribe(() => {
      this.clickOutside()
    })
  }

  clickOutside(): void {
    this.closeSubNavigationEvent.emit()
  }
}
